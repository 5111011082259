import background from "../img/laser2.gif";

function NoPage(){
    return (
        <div data-src={background} uk-img="loading: eager; margin: 50%" className="uk-height-viewport uk-flex uk-flex-center uk-flex-middle uk-background-contain">
            <h1 className="uk-text-center" style={{color: "#FC0AA8",fontFamily: 'Teko'}}>NOT FOUND</h1>
        </div>
    )
}

export default NoPage;