import logo from "../img/Cy_Waifu_Cntr0llz.png";


function Home(){
    return(
        <div data-src={logo} uk-img="loading: eager; margin: 50%" className="uk-height-viewport uk-flex uk-flex-center uk-flex-middle uk-background-cover">
                <h1 className="uk-text-center" style={{color: "whitesmoke",fontFamily: 'Teko'}}>UNDER CONSTRUCTION</h1>
            </div>
    )
}

export default Home;