import ctfback from "../../../vid/IMG_3263.MP4";

function Ctf2023(){
    return (

    <div className="uk-cover">
        <video className="uk-cover-object" width="100%" playsInline loop uk-video="autoplay: inview" src={ctfback}>
        </video>
        <div className="uk-position-cover uk-flex uk-flex-center uk-flex-middle">
            <h1 className="uk-text-center" style={{color: "whitesmoke",fontFamily: 'Teko'}}>COMING SOON...</h1>
        </div>
    </div>
    )
}

export default Ctf2023;