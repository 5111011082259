import {
    Link as LinkUiKit,
    Icon,
    List,
    ListItem,
    Navbar,
    NavbarContainer,
    NavbarSticky,
    Offcanvas,
    OffcanvasContainer,
    Section
} from "uikit-react";
import {Outlet, Link, useLocation} from "react-router-dom";
import React, {useState, useEffect} from 'react';
import {BrowserView, MobileView} from 'react-device-detect';


function Layout() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);

    return (
        <>
            {loading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>
                    <BrowserView>
                        <Navbar style={{marginLeft: "20px"}}>
                            <List className={"uk-navbar-nav"}>
                                <li></li>
                                <li><Link to="/">HOME</Link></li>
                                <ListItem >
                                    <Link to="/about">ABOUT</Link>
                                </ListItem>
                                <ListItem>
                                    <Link to="/members">MEMBERS</Link>
                                </ListItem>
                                <ListItem >
                                    <Link to="/contact">CONTACT US</Link>
                                </ListItem>
                                <ListItem>
                                    <Link to="/ctf">CTF 2023</Link>
                                </ListItem>
                            </List>
                        </Navbar>
                    </BrowserView>
                    <MobileView>
                        <OffcanvasContainer>
                            <Section>
                                <NavbarSticky
                                    options="animation: uk-animation-slide-top; cls-inactive: uk-navbar-transparent uk-light; top: 556;">
                                    <NavbarContainer>
                                        <Navbar>
                                            <ListItem>
                                                <LinkUiKit toggleOptions="target: #menu;" href="#">
                                                    <Icon options="menu" button/>
                                                </LinkUiKit>
                                            </ListItem>
                                        </Navbar>
                                    </NavbarContainer>
                                </NavbarSticky>
                                <Offcanvas id="menu" options="overlay: true">
                                    <List type="divider">
                                        <ListItem>
                                            <Link to="/">HOME</Link>
                                        </ListItem>
                                        <ListItem>
                                            <Link to="/about">ABOUT</Link>
                                        </ListItem>
                                        <ListItem>
                                            <Link to="/members">MEMBERS</Link>
                                        </ListItem>
                                        <ListItem>
                                            <Link to="/contact">CONTACT US</Link>
                                        </ListItem>
                                        <ListItem>
                                            <Link to="/ctf">CTF 2023</Link>
                                        </ListItem>
                                    </List>
                                </Offcanvas>
                            </Section>
                        </OffcanvasContainer>
                    </MobileView></>
            )}

            <Outlet/>
        </>
    )
}

export default Layout;