import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './App.css';
import {BrowserRouter, Routes, Route, HashRouter} from "react-router-dom";
import NoPage from "./components/nopage";
import Layout from "./components/layout";
import Home from "./components/home";
import Members from "./components/pages/members";
import Ctf2023 from "./components/pages/ctf2023";
const root = ReactDOM.createRoot(document.getElementById('root'));





function App() {
    return (
        <HashRouter>
            <Routes basename="/">
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="about" />
                    <Route path="members" element={<Members />} />
                    <Route path="contact" />
                    <Route path="ctf" element={<Ctf2023 />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </HashRouter>
    );
}

root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
